import { InjectionToken } from '@angular/core';
import { type ResourceTypes } from './models/resource.types';

/**
 * InjectionToken to provide a ResourcePath like this:
 *
 * `{ provide: RESOURCE_PATH_TOKENS, useValue: { [ResourceTypes.Inquiries]: '/inquiries' }, multi: true },`
 *
 */
export const RESOURCE_PATH_TOKEN: InjectionToken<Record<ResourceTypes, string>[]> = new InjectionToken<Record<ResourceTypes, string>[]>('RESOURCE_PATH_TOKEN');

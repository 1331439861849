import { InjectionToken, type Type } from '@angular/core';
import { ResourceTypes } from '../models/resource.types';
import { type ResourceMapper } from './resource/resource.mapper';
import { MapperInjectorService } from './mapper-injector.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RestApiResourceMapper: <T>(constructor: Type<T>) => void = <T>(constructor: Type<T>): void => {
    const t: typeof ResourceMapper = constructor as unknown as typeof ResourceMapper;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!t.type || !Object.values(ResourceTypes).includes(t.type)) {
        throw new Error(`Cannot register ResourceMapper '${t.name}'. Type '${t.type}' is not defined in ResourceTypes`);
    }

    MapperInjectorService.tokens[t.type] = new InjectionToken<ResourceMapper>(t.type);
};

import { Injectable } from '@angular/core';
import { isPartOf } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { type RouterTranslatePathResource } from '../../models/resources/router-translate-path.resource';
import { RestApiResourceMapper } from '../rest-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';
import { SECTION_IDS, type SectionLockId, type TranslatedPathModel } from '@big-direkt/utils/environment';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class RouterTranslatePathMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.RouterTranslatePath;

    public map(resource: RouterTranslatePathResource): TranslatedPathModel {
        const { entity, jsonapi } = resource.attributes;

        return {
            type: jsonapi.resourceName,
            uuid: entity.uuid,
            maintenanceTags: entity.maintenanceTags?.map(maintenanceTag => {
                if (!isPartOf<SectionLockId>(maintenanceTag, SECTION_IDS)) {
                    // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
                    console.error(`unknown maintenanceTag: ${maintenanceTag}. Add the maintenance to the known ids or check cms for valid ids`);
                }

                return maintenanceTag;
            }),
        };
    }
}

import { Injectable } from '@angular/core';
import { type UserEpqModel } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { type EpqResource } from '../../models/resources/epq.resource';
import { RestApiResourceMapper } from '../rest-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class EpqMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.Epq;

    public map(resource: EpqResource): UserEpqModel {
        return {
            status: resource.attributes.status,
            url: resource.attributes.url,
        };
    }
}

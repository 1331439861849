import { Injectable } from '@angular/core';
import { SickNotesModel } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { SickNotesResource } from '../../models/resources/sick-notes.resource';
import { RestApiResourceMapper } from '../rest-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class SickNotesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.SickNotes;

    public map(resource: SickNotesResource): SickNotesModel[] {
        if (!resource.attributes.data?.length) {
            return [];
        }

        return resource.attributes.data.map(sickNote => ({
            type: sickNote.notification_type,
            typeOfCertificate: sickNote.type_of_certificate,
            workOrOtherAccitent: sickNote.work_or_other_accident,
            transitDoctorAssigned: sickNote.transit_doctor_assigned,
            otherAccident: sickNote.other_accident,
            suffering: sickNote.suffering,
            medicalRehabilitation: sickNote.medical_rehabilitation,
            gradualReintegration: sickNote.gradual_reintegration,
            sicknessMoney: sickNote.sickness_money,
            finalCertificate: sickNote.final_certificate,
            benefit: {
                start: new Date(sickNote.benefit_start),
                end: new Date(sickNote.benefit_end),
            },
            diagnosis: {
                text: sickNote.diagnosis_text,
                code: sickNote.diagnosis_code,
            },
            doctor: {
                title: sickNote.doctor_title,
                firstName: sickNote.doctor_firstname,
                lastName: sickNote.doctor_name,
                number: sickNote.doctor_number,
                workplaceNumber: sickNote.doctor_workplace_number,
            },
        }));
    }
}

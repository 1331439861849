import { Injectable } from '@angular/core';
import { type Resource } from '../models/resource';
import { type ResourceTypes } from '../models/resource.types';
import { type Response } from '../models/response';
import { MapperInjectorService } from './mapper-injector.service';
import { type ResourceMapper } from './resource/resource.mapper';

@Injectable({
    providedIn: 'root',
})
export class ResponseMapperService {
    public constructor(private readonly mapperInjectorService: MapperInjectorService) {}

    public mapSingle<T>(type: ResourceTypes, document: Response): T {
        const data: Resource = {
            type,
            attributes: document,
        };

        const mapper: ResourceMapper = this.mapperInjectorService.get(type);

        return mapper.map(data) as T;
    }
}

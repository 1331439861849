import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type ResourceTypes } from './models/resource.types';
import { type Response } from './models/response';
import { RESOURCE_PATH_TOKEN } from './resource-path';
import { ResponseMapperService } from './response-mapper/response-mapper.service';

@Injectable({
    providedIn: 'root',
})
export class RestApiClientService {
    private readonly httpClient = inject(HttpClient);
    private readonly responseMapper = inject(ResponseMapperService);
    private readonly environment = inject(EnvironmentService);
    private readonly resourcePathToken = inject(RESOURCE_PATH_TOKEN);

    public load<T>(type: ResourceTypes, pathExtension?: string, options: Record<string, unknown> = {}): Observable<T> {
        const url: string = this.buildUrl(type, pathExtension);

        return this.httpClient.get(url, options).pipe(map((document: Response): T => this.responseMapper.mapSingle<T>(type, document)));
    }

    public post<T>(type: ResourceTypes, pathExtension?: string, body: unknown = {}, options: Record<string, unknown> = {}): Observable<T> {
        const url: string = this.buildUrl(type, pathExtension);

        return this.httpClient.post<T>(url, body, options);
    }

    private getResourcePath(type: ResourceTypes): string {
        const resourcePath: Record<ResourceTypes, string> | undefined = this.resourcePathToken.find((token: Record<ResourceTypes, string>) => type in token);

        if (resourcePath) {
            return resourcePath[type];
        }

        throw new Error(`Cannot load resource. Path for resource type '${type}' is not defined`);
    }

    private buildUrl(type: ResourceTypes, pathExtension: string | undefined): string {
        let path: string = this.getResourcePath(type);

        if (pathExtension !== undefined) {
            path = `${path}${pathExtension}`;
        }

        const url: string = this.formatUrl(path);

        return url;
    }

    private formatUrl(path: string): string {
        return new URL(path, this.environment.baseHref).toString();
    }
}

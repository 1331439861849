/* eslint-disable no-shadow, @typescript-eslint/naming-convention */
/** TODO Find solution to modularize this enum */
export enum ResourceTypes {
    AdvantageCalculatorServiceToolData = 'advantage-calculator-service-tool-data',
    AliveLink = 'alive-link',
    AppliancesSearchAids = 'appliances-search-aids',
    AppliancesSearchLocation = 'appliances-search-location',
    AppliancesSearchResults = 'appliances-search-result',
    AppliancesSearchTopAids = 'appliances-search-top-aids',
    BgmLink = 'bgm-link',
    BonusMeasures = 'bonus-measures',
    Documents = 'documents',
    DocumentsUnreadCount = 'documents-unread-count',
    DPPContactPerson = 'dpp-contact-person',
    Epq = 'epq',
    FamilyHealthRetreatSearchData = 'family-health-retreat-search',
    ImageCreditsResults = 'image-credits-results',
    Inquiries = 'inquiries',
    InsuranceServicesNodes = 'insurance-services-nodes',
    MedicalAppointment = 'medical-appointment',
    Messages = 'messages',
    MessagesUnreadCount = 'messages-unread-count',
    PreventionCheckServiceToolData = 'prevention-check-service-tool-data',
    RouterTranslatePath = 'router-translate-path',
    AccountStatements = 'account-statements',
    SectionLock = 'section-lock',
    SickNotes = 'sick-notes',
    SpecialistSearchSpecialists = 'specialist-search-specialists-service-tool',
    SpecialistSearchSpeciality = 'specialist-search-speciality-service-tool',
    SubjectAreaNodes = 'subject-area-nodes',
    SubjectAreas = 'subject-area-terms',
    Suggest = 'suggest',
    ToothReplacementSubsidyServiceToolData = 'tooth-replacement-subsidy-service-tool-data',
    UserConsent = 'user-consent',
    UserLogout = 'user-logout',
    UserProfile = 'user-profile',
    VaccinationCheckServiceToolData = 'vaccination-check-service-tool-data',
    VaccinationTravelCountries = 'vaccination-travel-countries',
    VaccinationTravelCountry = 'vaccination-travel-country',
    VaccinationTravelYellowFeverCountries = 'vaccination-travel-yellow-fever-countries',
}

import { Injectable } from '@angular/core';
import { type MedicalAppointmentModel } from '@big-direkt/utils/shared';
import { ResourceTypes } from '../../models/resource.types';
import { type MedicalAppointmentResource } from '../../models/resources/medical-appointment.resource';
import { RestApiResourceMapper } from '../rest-api-resource-mapper.decorator';
import { ResourceMapper } from './resource.mapper';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class MedicalAppointmentMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.MedicalAppointment;

    public map(resource: MedicalAppointmentResource): MedicalAppointmentModel[] {
        const results: MedicalAppointmentModel[] = [];

        for (const item of Object.values(resource.attributes.data)) {
            let itemResult: MedicalAppointmentModel = {} as unknown as MedicalAppointmentModel;

            for (const [key, value] of Object.entries(item)) {
                // eslint-disable-next-line no-null/no-null
                if (value === null) {
                    continue;
                }

                if (key === 'created' || key === 'date') {
                    itemResult = { ...itemResult, [key]: new Date(value) };

                    continue;
                }

                itemResult = { ...itemResult, [key]: value };
            }

            results.push(itemResult);
        }

        return results;
    }
}
